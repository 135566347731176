
/* This is the moving lens square underneath the mouse pointer. */
.cloud-zoom-lens {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

/* This is for the title text. */
.cloud-zoom-title {
	font-family:Arial, Helvetica, sans-serif;
	position:absolute !important;
	background-color:#000;
	color:#fff;
	padding:3px;
	width:100%;
	text-align:center;
	font-weight:bold;
	font-size:10px;
	top:0px;
}

/* cloud zoom wrapper styles */
.cloud-zoom-wrap {
    top:0;
    z-index:9999;
    position:relative;
}

/* This is the zoom window. */
.cloud-zoom-big {
	overflow:hidden;
	left: 0px;
}

/* This is the loading message. */
.cloud-zoom-loading {
	color:white;
	background:#222;
	padding:3px;
	border:1px solid #000;
}


